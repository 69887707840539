import { render, staticRenderFns } from "./ContentTeaser.vue?vue&type=template&id=67254d74&"
import script from "./ContentTeaser.vue?vue&type=script&lang=js&"
export * from "./ContentTeaser.vue?vue&type=script&lang=js&"
import style0 from "./ContentTeaser.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageCopyright: require('/usr/src/app/components/Partials/ImageCopyright.vue').default,Picture: require('/usr/src/app/components/Configurator/components/Picture.vue').default})
